import axios from 'axios';
import authHeader from '../EmploypanalApi/setAuthHeader';
let API_URL = process.env.VUE_APP_ROOT_EMPLOYEE_API

class profileApi {
    get() {
        return axios.get(API_URL + 'profile', { headers: authHeader() });
    }

}
export default new profileApi();