<script setup>
import SecondaryButton from '@/Components/SecondaryButton.vue';
import PrimaryButton from '@/Components/PrimaryButton.vue';
import InputLabel from '@/Components/InputLabel.vue';
import InputError from '@/Components/InputError.vue';
import TextInput from '@/Components/TextInput.vue';
import { ref, defineProps, defineEmits, onMounted } from 'vue';
import Modal from '@/Components/Modal.vue';
import {
    validatePassword
} from "@/Validation/validateChangePassword";
import { useStore } from 'vuex';

const store = useStore();
const form = ref({});
const formErrors = ref({});
const props = defineProps({
    changePasswordModal: Boolean
})

const emit = defineEmits(['submit', 'close-modal']);

const updatePassword = async () => {

    const errors = validatePassword(form.value);
    formErrors.value = _.keyBy(errors.error?.details, 'context.key');

    if (!Object.keys(formErrors.value).length) {
        let payloads = {
            url : 'users/changePassword',
            data: form.value
        }
        await store.dispatch('updateRecord', payloads).then((response) => {
            store.commit('add', {
                message: response.data.message,
                type: 'success',
                duration: 3000,
            });
            emit('submit');
            form.value = {}
        }).catch(error => {
           if (error.response) {
                    store.commit('add', {
                        message: error.response.data.message,
                        type: 'danger',
                        duration: 3000
                    });
                }
        })
        
    } else {
        setTimeout(() => {
            formErrors.value = {};
        }, 5000)
    }
}
</script>

<template>
    <Modal :isModalOpen="changePasswordModal">
        <template #title>
           Change Password
        </template>
        <form>
            <div class="mt-3 grid grid-cols-1 gap-x-6 gap-y-8">
                <div class="col-span-3">

                    <InputLabel for="Old Password" value="Old Password"
                        class="block mb-1 font-medium after:content-['*'] after:ml-0.5 after:text-red-500" />
                        <TextInput id="old_password" type="text" class="mt-1 block w-full" v-model="form.old_password" required autofocus
                        autocomplete="name" placeholder="Enter your Old Password" />
                        <InputError class="mt-2" :message="formErrors?.old_password?.message" />
                    </div>

                    <div class="col-span-3">
                        <InputLabel for="Password" value="Password"
                        class="block mb-1 font-medium after:content-['*'] after:ml-0.5 after:text-red-500" />
                        <TextInput id="password" type="text" class="mt-1 block w-full" v-model="form.password" required autofocus
                        autocomplete="name" placeholder="Enter your Password" />
                        <InputError class="mt-2" :message="formErrors?.password?.message" />
                    </div>

                    <div class="col-span-3">
                        <InputLabel for="password confirmation" value="password confirmation"
                        class="block mb-1 font-medium after:content-['*'] after:ml-0.5 after:text-red-500" />
                        <TextInput id="password_confirmation" type="text" class="mt-1 block w-full" v-model="form.password_confirmation" required autofocus
                        autocomplete="name" placeholder="Enter your Password Confirmation" />
                        <InputError class="mt-2" :message="formErrors?.password_confirmation?.message" />
                    </div>

            </div>
        </form>

        <template #footer>
            <PrimaryButton @click="updatePassword">submit</PrimaryButton>
            <SecondaryButton @click="emit('close-modal')">Cancel</SecondaryButton>
        </template>
    </Modal>
</template>
