import { createApp } from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import './assets/tailwind.css'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import {LoadingPlugin} from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
import VueApexCharts from "vue3-apexcharts";



const app = createApp(App)
app.use(LoadingPlugin);
app.use(router);
app.use(VueApexCharts);
app.use(store);
app.use(VueSweetalert2);
app.use('VueDatePicker', VueDatePicker)
app.mount('#app')
