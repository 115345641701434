const Joi = require('joi');

export const validatePassword = (data) => {
 
    const schema = Joi.object({
        old_password: Joi.string().required().max(100).label('Old Password'),
        password: Joi.string().required().max(100).label('Password'),
        password_confirmation: Joi.string().required().max(100).label('Password Confirmation'),
    });
   
    return schema.validate(data, { abortEarly: false, allowUnknown: true });
};