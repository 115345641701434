const Joi = require('joi');

export const validateProfile = (data) => {
 
    const schema = Joi.object({
        name: Joi.string().required().max(100).label('Name'),
        email: Joi.string().required().email({ tlds: { allow: false } }).label('email'),
    });
   
    return schema.validate(data, { abortEarly: false, allowUnknown: true });
};

export const validateEmployeeProfile = (data) => {
 
    const schema = Joi.object({
        first_name: Joi.string().required().max(100).label('Name'),
        email: Joi.string().required().email({ tlds: { allow: false } }).label('email'),
    });
   
    return schema.validate(data, { abortEarly: false, allowUnknown: true });
};