import axios from 'axios';
import authHeader from './setAuthHeader';
let API_URL = process.env.VUE_APP_ROOT_API

class profileApi {
    get() {
        return axios.get(API_URL + 'users/profile', { headers: authHeader() });
    }

}
export default new profileApi();