<script setup>
import Profile from '@/Pages/EmployeePanel/Profile/Profile.vue';
import changePassword from '@/Pages/EmployeePanel/ChangePassword/changePassword.vue';
import { UserCircleIcon, UserIcon, HomeIcon, AcademicCapIcon, HandThumbUpIcon, PlusCircleIcon, ChevronDoubleLeftIcon, ClipboardDocumentIcon, ChevronDownIcon } from '@heroicons/vue/24/outline';
import { HandRaisedIcon, LockClosedIcon, ArrowRightOnRectangleIcon, BellAlertIcon } from '@heroicons/vue/24/solid';
import { ref, onMounted } from 'vue';
import ToastList from '@/Components/ToastList.vue';
import router from '@/router';
import profileApi from '@/Utils/EmploypanalApi/ProfileApi';

const handleNavigation = (event, name) => {
    if (name === 'logout') {
        event.preventDefault();
        localStorage.clear();
        router.push('/employee/login');
    }
}

const isExpanded = ref(localStorage.getItem('isExpanded') === 'true');
const ToggleMenu = () => {
    isExpanded.value = !isExpanded.value;
    localStorage.setItem('isExpanded', isExpanded.value);
};

const getProfile = () => {
    profileApi.get().then(response => {
        profile.value = response.data.data.image;
    }).catch(error => {
        console.log(error);
    })
}
onMounted(() => {
    getProfile();
});

const profile = ref('')
const profileModal = ref(false);
const changePasswordModal = ref(false);
let open = ref(true);
let rednerComponent = ref(true);
let updateKey = ref(0);

const navigationItems = [
    { name: 'Dashboard', href: '/employee/dashbord', icon: HomeIcon, current: false },
    { name: 'TodoList', href: '/employee/todo', icon: PlusCircleIcon, current: false },
    { name: 'Projects', href: '/employee/projects', icon: ClipboardDocumentIcon, current: false, margin: false },
    { name: 'Attendances', href: '/employee/attendances', icon: HandRaisedIcon, current: false },
    { name: 'Leaves', href: '/employee/leaves', icon: UserCircleIcon, current: false, margin: false },
    { name: 'Holidays', href: '/employee/holidays', icon: BellAlertIcon, current: false, margin: false },
    { name: 'Current Trainings', href: '/employee/current-tranings', icon: AcademicCapIcon, current: false },
    { name: 'Completed Trainings', href: '/employee/completed-tranings', icon: HandThumbUpIcon, current: false, margin: false },
    { name: 'Profile', href: '/employee/profile', icon: UserIcon, current: false },
];
</script>

<style scoped>
/* Your existing styles */
</style>

<template>
    <Profile v-if="profileModal" :isModalOpen="profileModal" @closeModal="profileModal = false" @submit="getProfile"/>
    <changePassword :isModalOpen="changePasswordModal" @submit="changePasswordModal = false"
        @close-modal="changePasswordModal = false" />
    <div>
        <div>
            <div class="flex">
                <div class="hidden md:fixed md:inset-y-0 md:flex md:w-64 md:flex-col z-10 ml-3">
                    <div class="flex min-h-[90%] flex-col my-auto shadow-2xl bg-white rounded-2xl p-2">
                        <router-link to="/" class="hover:no-underline">
                            <div class="flex gap-3 justify-center items-center rounded-2xl h-16 flex-shrink-0 bg-white">
                                <img class="object-cover h-14 w-14" src="https://ims-api-dev.octalinfotech.com/storage/logo_images_/1714472300.png" alt="Your Company" />
                                <h1 class="text-black text-lg font-bold space-x-3">Octal Infotech</h1>
                            </div>
                            <hr class="text-gray-50 w-40 mx-auto" />
                        </router-link>
                        <div class="flex flex-1 flex-col overflow-y-auto">
                            <nav class="flex h-full flex-col justify-between space-y-3 px-2 py-4">
                                <div>
                                    <template v-for="(item) in navigationItems" :key="item.name">
                                        <router-link
                                            :to="item.href ? item.href : '/'"
                                            class="keyFrame mb-2  button flex p-2 hover:shadow-xl rounded-[20px] hover:scale-[1.01] transition-all hover: hover:no-underline space-x-3"
                                            :class="{  '  bg-cyan-900 text-white !rounded-[22px] ': $route.path === item.href }"
                                        >
                                            <component :is="item.icon" class="flex-shrink-0 h-6 w-6" aria-hidden="true" />
                                            <h3>{{ item.name }}</h3>
                                        </router-link>
                                    </template>
                                </div>
                                <div>
                                    <RouterLink to="/" class="text-decoration-none ">
                    <div class="flex justify-center    px-8 py-2    text-white   bg-gray-800  rounded-lg ">
               <a href="/login"
               :class="[active ? 'bg-gray-100' : '', 'block px-2  text-sm text-white font-bold']"
               @click="handleNavigation($event, 'logout')">
               <ArrowRightOnRectangleIcon class="inline-block w-6 h-6 mx-2" />
               Logout
           </a>
               <!-- <a href="/login"
               @click="handleNavigation($event, 'logout')">
               <ArrowRightOnRectangleIcon class="inline-block w-6 h-6 mx-2" />
               Logout
           </a> -->
                      <!-- <ArrowUturnLeftIcon class="w-5 h-5" /> -->
                    </div>
                  </RouterLink>
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>
                <div class="flex flex-col md:pl-64"></div>
                 <main class="w-full text-gray-600 duration-500" v-if="rednerComponent">

                    <div :key="updateKey" class=" mx-auto md:max-w-full px-10 sm:px-6 md:px-8 z-10 relative pt-8">
                        <ToastList />
                        <slot name="main"></slot>
                    </div>
                </main>
            </div>
            </div>
        </div>
    <!-- </div> -->
</template>
