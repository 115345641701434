import axios from 'axios';
import authHeader from './setAuthHeader';
let API_URL = process.env.VUE_APP_ROOT_API

class settingApi {

  storedSetting(data) {
    return axios.post(API_URL + `settings/store`,data, { headers: authHeader() });
  }

  updateSetting(data) {
    return axios.post(API_URL + `settings` + '/update', data, { headers: authHeader() });
  }
  showSetting(data) {
    return axios.post(API_URL + `settings/${id}` + "/show",data, { headers: authHeader() });
  }

  allSettings() {
    return axios.get(API_URL + 'settings', {headers: authHeader()});
  }


}

export default new settingApi();