<template>
  <div>
    <div v-show="!authRoute" class="absolute top-0 min-h-[30%] w-full bg-[#5E72E4] "
      style="background-image: url('https://raw.githubusercontent.com/creativetimofficial/public-assets/master/argon-dashboard-pro/assets/img/profile-layout-header.jpg') ;background-position-y: 50%; position:fixed; ">
      <span class="imgopasity"></span>
    </div>
    <router-view v-if="authRoute" />

    <Layout v-else-if="type === 'user'">
      <template #main>
        <router-view />
      </template>
    </Layout>

    <EmployeeLayout v-else>
      <template #main>
        <router-view />
      </template>
    </EmployeeLayout>
  </div>

</template>

<script>
import Layout from './Pages/Admin/Layouts/Layout.vue';
import EmployeeLayout from './Pages/EmployeePanel/Layouts/Layout.vue';

export default {
  name: 'App',
  components: {
    Layout, EmployeeLayout
  },
  data() {
    return {
      authRoute: true,
      name: true,
      type: 'user'
    }
  },



  watch: {
    $route: {
      handler: function (path) {
        this.authRoute = (path.name === 'login' || path.name === 'register' || path.name === 'Employeelogin' || path.name === 'Layouts')
        let token = localStorage.getItem('token');
        let user = localStorage.getItem('user');
        if (this.$store.state.user.type) {
          this.type = this.$store.state.user.type
        }
        if (!token && path.name !== 'Employeelogin' && !path.name === 'Layouts') {
          this.$router.push('/admin/login');
        }
      },
    }
  },
  mounted() {
    this.$store.commit('initialiseStore');
  }

}
</script>


<style>
@import "@vueform/multiselect/themes/default.css";
</style>
<style scoped>
.imgopasity {
  /*background-color: #5E72E4;*/
  position: absolute;
  background-position: 50%;
  top: 0;
  left: 0;
  background-image: linear-gradient(310deg, #1c53b9ca, #00ADBE);
  width: 100%;
  height: 100%;
  opacity: 0.7;

}
</style>