import { createWebHistory, createRouter } from "vue-router";
import auth from "./Middleware/auth";
import admin from "../src/Middleware/admin";
import middlewarePipeline from "./Middleware/middleware-pipeline";

const routes = [
  // {
  //   path: "/",
  //   redirect: "/employee/dashbord",
  // },
    {
      path: "/",
      name: "Layouts",
      component: () => import("../src/LanddingPage/Layoutss.vue")
    },
    {
        path: "/admin/dashbord",
        name: "Dashbord",
        meta: {
            middleware: [
                auth,
                admin
            ],
            title:'Dashbord'
        },
        component: () => import("./Pages/Admin/Dashboard/Index.vue")
    },
    {
      path: "/employee/dashbord",
      name: "Employeedashboard",
      meta: {
        middleware: [
          auth
         ],
         title:'Dashboard'
         },
      component: () => import("./Pages/EmployeePanel/Dashboard/Index.vue")
    },
    {
        path: '/admin/login',
        name: 'login',
        component: () =>
            import('./Pages/Admin/Auth/Login.vue'),
    },
  {
    path: '/employee/profile',
    name: 'profile',
    component: () =>
        import('./Pages/EmployeePanel/Profile/Profile.vue'),
},

    {
      path: '/employee/login',
      name: 'Employeelogin',
      component: () =>
        import('./Pages/EmployeePanel/Auth/Login.vue'),
    },
    {
      path: '/employee/layout',
      name: 'Employeelayout',
      component: () =>
        import('./Pages/EmployeePanel/Layouts/Layout.vue'),
    },
    {
      path: '/employee/leaves',
      name: 'Employeeleave',
      meta: {
        middleware: [
          auth,
         ],
         title:'Leaves'
         },
      component: () =>
        import('./Pages/EmployeePanel/Leave/List.vue'),
    },
    {
      path: '/employee/attendances',
      name: 'EmployeeAttendance',
      meta: {
        middleware: [
          auth,
         ],
         title:'Attendances'
         },
      component: () =>
        import('./Pages/EmployeePanel/Attendance/List.vue'),
    },
    {
      path: '/employee/holidays',
      name: 'Holidays',
      meta: {
        middleware: [
          auth,
         ],
         title:'Holidays'
         },
      component: () =>
        import('./Pages/EmployeePanel/EmployeeHolidays/List.vue'),
    },
    {
        path: "/admin/employees",
        name: "EmployList",
        meta: {
          middleware: [
            auth,
           ],
           title:'Employees'
           },
        component: () => import("./Pages/Admin/Employee/List.vue")
      },
      {
        path: "/employee/create",
        name: "Employcreate",
        meta: {
          middleware: [
            auth,
           ],
           title:'New Employee',
           },
        component: () => import("./Pages/Admin/Employee/Create.vue")
      },
      {
        path: "/employee/edit/:id",
        name: "Employedit",
        meta: {
          middleware: [
            auth,
           ],
           title:'Update Employee',
           },
        component: () => import("./Pages/Admin/Employee/Edit.vue")
      },

      // --profiles admin

      {
        path: "/admin/profiles",
        name: "Profile",
        meta: {
          middleware: [
            auth,
           ],
           title:'Update Employee',
           },
        component: () => import("./Pages/Admin/Profile/Profile.vue")
      },
      {
        path: "/admin/employee/view/:id",
        name: "Employeeview",
        meta: {
          middleware: [
            auth,
           ],
           title:'Employment Status View',
           },
        component: () => import("./Pages/Admin/Employee/View.vue")
      },
      {
        path: "/admin/employees/views/:id",
        name: "Employeeviews",
        meta: {
          middleware: [
            auth,
           ],
           title:'Employee Personal Information', 
           },
        component: () => import("./Pages/Admin/Employee/EmployeeView.vue")
      },


      {
        path: "/admin/interviews",
        name: "InterviewList",
        meta: {
          middleware: [
            auth,
           ],
           title:'Interviews'
           },
        component: () => import("./Pages/Admin/Interview/List.vue")
      },
      {
        path: "/interview/create",
        name: "Interviewcreate",
        meta: {
          middleware: [
            auth,
           ],
           title:'New Interview',
           },
        component: () => import("./Pages/Admin/Interview/Create.vue")
      },
      {
        path: "/interview/edit/:id",
        name: "Interviewedit",
        meta: {
          middleware: [
            auth,
           ],
           title:'Update Interview ',
           },
        component: () => import("./Pages/Admin/Interview/Edit.vue")
      },

      {
        path: "/admin/role-permissions",
        name: "RoleList",
        meta: {
          middleware: [
            auth,
           ],
           title:'Roles'
           },
        component: () => import("./Pages/Admin/Rolepermission/List.vue")
      },
      {
        path: "/role/create",
        name: "Rolecreate",
        meta: {
          middleware: [
            auth,
           ]
           },
        component: () => import("./Pages/Admin/Rolepermission/Create.vue")
      },

      {
        path: "/admin/employment-status",
        name: "EmploymentList",
        meta: {
          middleware: [
            auth,
           ],
           title:'Employment Status'
           },
        component: () => import("./Pages/Admin/EmploymentStatus/List.vue")
      },
      
      {
        path: "/admin/employments",
        name: "EmploymentsList",
        meta: {
          middleware: [
            auth,
           ],
           title:'Employments'
           },
        component: () => import("./Pages/Admin/Employments/List.vue")
      },
      {
        path: "/admin/salaries",
        name: "SalariesList",
        meta: {
          middleware: [
            auth,
           ],
           title:'Salaries'
           },
        component: () => import("./Pages/Admin/Salary/Index.vue")
      },
      {
        path: "/todo",
        name: "Todo",
        meta: {
          middleware: [
            auth,
           ],
           title:'Empoyee Todos'
           },
        component: () => import("./Pages/Admin/Todo/Index.vue")
      },
      {
        path: "/admin/expenses",
        name: "ExpensesList",
        meta: {
          middleware: [
            auth,
           ],
           title:'Expenses'
           },
        component: () => import("./Pages/Admin/Expense/Index.vue")
      },
      {
        path: "/admin/users",
        name: "UserList",
        meta: {
          middleware: [
            auth,
           ],
           title:'Users'
           },
        component: () => import("./Pages/Admin/Users/List.vue")
      },
      
      {
        path: "/admin/leaves",
        name: "LeaveList",
        meta: {
          middleware: [
            auth,
           ],
           title:'Leaves'
           },
        component: () => import("./Pages/Admin/Leave/List.vue")
      },
      {
        path: "/admin/holidays",
        name: "HolidayList",
        meta: {
          middleware: [
            auth,
           ],
           title:'Holidays',
           },
        component: () => import("./Pages/Admin/Holidays/List.vue")
      },

      {
        path: "/admin/technologies",
        name: "TechnologyList",
        meta: {
          middleware: [
            auth,
           ],
           title:'Technologies'
           },
        component: () => import("./Pages/Admin/Technology/Index.vue")
      },
    {
        path: "/admin/clients",
        name: "ClientsList",
        meta: {
            middleware: [
                auth,
            ],
            title:'Clients'
        },
        component: () => import("./Pages/Admin/Clients/Index.vue")
    },
    {

      path: "/admin/attendances",
      name: "AttendanceList",
      meta: {
        middleware: [
          auth,
         ],
         title:'Attendances'
         },
      component: () => import("./Pages/Admin/Attendance/Index.vue")
    },

    // {
    //   path: "/trainings",
    //   name: "Trainings",
    //   meta: {
    //     middleware: [
    //       auth,
    //      ],
    //      title:'Trainings',
    //      traningTopicTitle:'Training Topics',
    //      completedTraningTitle:'Completed Tranings',
    //      },
    //   component: () => import("./Pages/Admin/Trainings/Index.vue")
    // },
    
    {
      path: "/admin/trainings",
      name: "Trainings",
      meta: {
        middleware: [
          auth,
         ],
        //  children: [{ path: '/trainingtopic', component: "./Pages/EmployeePanel/Completed Traning/Index.vue" }],
         title:'Trainings',
        //  traningTopicTitle:'Training Topics',
        //  completedTraningTitle:'Completed Tranings',
         },
      component: () => import("./Pages/Admin/Trainings/Index.vue")
    },

    {
      path: "/admin/projects",
      name: "ProjectIndex",
      meta: {
          middleware: [
              auth,
          ],
          title:'Projects'
      },
      component: () => import("./Pages/Admin/Project/Index.vue")
  },

   // ------------project employee in admin start----------

   {
    path: "/admin/employee-projects",
   name: "EmployeeProjectsIndex",
   meta: {
       middleware: [
           auth,
       ],
       title:'Employee Projects'
   },
   component: () => import("./Pages/Admin/EmployeeProject/Index.vue")
  },
  // ------------project work employee start----------
  {
    path: "/employee/projects",
   name: "ProjectWorkIndex",
   meta: {
       middleware: [
           auth,
       ],
       title:'Projects'
   },
   component: () => import("./Pages/EmployeePanel/Project Work/Index.vue"),
  },

  // ------------Completed tarining employee start----------
  {
    path: "/employee/completed-tranings",
   name: "CompletedTraningIndex",
   meta: {
       middleware: [
           auth,
       ],
       title:'Completed Tranings'
   },
   component: () => import("./Pages/EmployeePanel/Completed Traning/Index.vue")
},

{
  path: "/employee/current-tranings",
 name: "CurrentTraning",
 meta: {
     middleware: [
         auth,
     ],
     title:'Current Tranings'
 },
 component: () => import("./Pages/EmployeePanel/Current Traning/List.vue")
},
// ------------Completed tarining employee end----------

// ------------ tarining count employee start----------
{
  path: "/admin/training-topics",
 name: "Training Topic",
 meta: {
     middleware: [
         auth,
     ],
     title:'Training Topics', 
    
 },
 component: () => import("./Pages/Admin/TrainingTopic/Index.vue")
},
{
  path: "/training-topic/view/:id",
 name: "TrainingtopicView",
 meta: {
     middleware: [
         auth,
     ]
 },
 component: () => import("./Pages/Admin/TrainingTopic/Edit.vue")
},
// ------------ tarining count students end----------

{
  path: "/admin/completed-trainings",
 name: "Completed Tranings",
 meta: {
     middleware: [
         auth,
     ],
     title:'Completed Tranings'
 },
 component: () => import("./Pages/Admin/CompletedTraning/Index.vue")
},
{
  path: "/employee/todo",
 name: "TodoList",
 meta: {
     middleware: [
         auth,
     ],
     title:'TodoList'
 },
 component: () => import("./Pages/EmployeePanel/TodoList/Todo.vue")
},

{
  path: "/admin/notifications",
 name: "Notifications",
 meta: {
     middleware: [
         auth,
     ],
     title:'Notifications'
 },
 component: () => import("./Pages/Admin/Notification/Index.vue")
},
{
  path: '/admin/settings',
  name: 'Settings',
  meta:{
    middleware:[
      auth,
    ],
    title:'Settings'
  },
  component: () => import("./Pages/Admin/Setting/Index.vue")
}
]
const router = createRouter({
    history: createWebHistory(),
    routes,
});
router.beforeEach((to, from, next) => {

    /** Navigate to next if middleware is not applied */
    if (!to.meta.middleware) {
        return next()
       
    }
  
    const middleware = to.meta.middleware;
    const context = {
      
      to,
      from,
      next,
      //   store  | You can also pass store as an argument
    }
  
    return middleware[0]({
        ...context,
        next:middlewarePipeline(context, middleware,1)
    })
  })
export default router;
