export default function admin({next,store}){

  let user = localStorage.getItem("user");

  if (user) {
    user = JSON.parse(user);

    let isEmployee = user.type === 'employee'
    if (isEmployee) {
      return next({
        name: 'NotFound'
      })
    }
    return next();
  }
  return next({
    name: 'login'
  })

}
  