<script setup>
import { DocumentDuplicateIcon, ArrowUturnLeftIcon, HandRaisedIcon, ArrowRightOnRectangleIcon, DocumentCheckIcon, AcademicCapIcon, UserPlusIcon, UserCircleIcon, HomeIcon, UserGroupIcon, ShieldCheckIcon, ClipboardDocumentIcon, RocketLaunchIcon, NewspaperIcon, UsersIcon, BanknotesIcon, BellIcon, BellAlertIcon, Cog6ToothIcon, PlusCircleIcon, ChevronDownIcon, CheckCircleIcon, CurrencyDollarIcon } from '@heroicons/vue/24/outline';
import profileApi from '@/Utils/ProfileApi';
import ChangePassword from '@/Pages/Admin/ChangePassword/ChangePassword.vue';
import { ref, onMounted } from 'vue';
import ToastList from '@/Components/ToastList.vue';
import router from '@/router';
import store from "@/store";
import settingApi from '@/Utils/settingApi';

const company_logo = ref('');
const company_name = ref('');
let user = localStorage.getItem('user');

if (user) {
    user = JSON.parse(user);
}

const profile = ref('');
const isExpanded = ref(localStorage.getItem('isExpanded') === 'true');

const ToggleMenu = () => {
    isExpanded.value = !isExpanded.value;
    localStorage.setItem('isExpanded', isExpanded.value);
};

const handleNavigation = (event, name) => {
    if (name === 'logout') {
        event.preventDefault();
        localStorage.clear();
        router.push('/admin/login');
    }
}

const isAllow = (item) => {
    return item.name && item.subMenu && item.subMenu.filter(element => element !== '').length > 0;
}

const getSettings = () => {
    settingApi.allSettings().then((res) => {
        company_logo.value = res.data.data.company_logo;
        company_name.value = res.data.data.company_name;
    }).catch((err) => {
        console.log(err.response, err.response?.data?.message)
        if (err.response?.data?.message === 'Unauthenticated.') {
            router.push('/admin/login')
        }
    });
}

onMounted(() => {
    getSettings();
});

const changePasswordModal = ref(false);
let open = ref(true);
let rednerComponent = ref(true);
let updateKey = ref(0);

const navigationItems = ref([
{ name: 'Dashboard', href: '/admin/dashbord', icon: HomeIcon, current: false},


store.state.permissions.includes('employees') ? { name: 'Employees', href: '/admin/employees', icon: UserCircleIcon, current: false, margin: false,
 subMenu: [
    
 { name: 'Employees', href: '/admin/employees', current: false,icon: UserCircleIcon },
 { name: 'Employee Projects', href: '/admin/employee-projects', current: false ,icon: ClipboardDocumentIcon},
 { name: 'Attendances', href: '/admin/attendances', current: false, icon: HandRaisedIcon },
 { name: 'Leaves', href: '/admin/leaves', current: false , icon:DocumentCheckIcon },
 { name: 'Salaries', href: '/admin/salaries', current: false,icon:BanknotesIcon },
 { name: 'Todo', href: '/todo', current: false , icon:PlusCircleIcon },
 { name: 'Employments', href: '/admin/employments', current: false, icon:UserGroupIcon},
 { name: 'Employment Status', href: '/admin/employment-status', current: false , icon:ShieldCheckIcon },
 
]  }: {},

  
store.state.permissions.includes('projects') ? { name: 'Projects', href: '/admin/projects', icon: ClipboardDocumentIcon, current: false , 
subMenu:[
    {name:'Projects', href:'/admin/projects', current:false , icon:DocumentDuplicateIcon},
    {name:'Clients', href:'/admin/clients', current:false , icon:UsersIcon}
]}: {},

store.state.permissions.includes('notification') ? { name: 'Notifications', href: '/admin/notifications', icon: BellAlertIcon, current: false,
subMenu:[
    { name: 'Notifications', href: '/admin/notifications', current: false , icon: BellAlertIcon },
    { name: 'Holidays', href: '/admin/holidays', current: false , icon:BellIcon}
]

} : {}, 


store.state.permissions.includes('training') ? { name: 'Trainings', href: '/admin/trainings', icon: AcademicCapIcon, current: false,
subMenu:[
    {name:'Trainings', href:'/admin/trainings' , current:false ,icon: AcademicCapIcon},
    {name:'Training Topics' , href:'/admin/training-topics' , current:false ,icon:NewspaperIcon},
    {name:'Completed Trainings' , href:'/admin/completed-trainings' , icon:CheckCircleIcon}
]

} :{},



store.state.permissions.includes('interview') ? { name: 'Candidates', href: '/admin/interviews', icon: UserPlusIcon, current: false , 
subMenu:[
    {name: 'Interviews', href: '/admin/interviews', current: false, icon:UserGroupIcon}

]}: {},

 store.state.permissions.includes('client') ?  { name: 'Settings', href: '/admin/settings', icon: Cog6ToothIcon, current: false,
subMenu:[
    { name: 'Settings', href: '/admin/settings', current: false , icon: Cog6ToothIcon },
    { name: 'Technologies', href: '/admin/technologies', current: false , icon:RocketLaunchIcon},
    {name:'Users' , href:'/admin/users' , current:false , icon:UserGroupIcon},
    {name:'Roles' , href:'/admin/role-permissions' , current:false ,icon:AcademicCapIcon},
    { name: 'Expenses', href: '/admin/expenses',current: false,icon:CurrencyDollarIcon }, 
    
] } :{},
{ name: 'Profile', href: '/admin/profiles',current: false,icon:UsersIcon } 
]);

const subMenuHandle = ref(navigationItems.value.map(() => false));

const manageSubMenu = (index) => {
    subMenuHandle.value = subMenuHandle.value.map((item, i) => (i === index ? !item : false));
};

const isActiveRoute = (route) => {
    return router.currentRoute.value.path === route;
};

</script>

<!--  -->
<template>
    <changePassword :isModalOpen="changePasswordModal" @submit="changePasswordModal = false"
        @close-modal="changePasswordModal = false" />
    <div>
        <div class="flex">
            <div class="hidden md:fixed md:inset-y-0 md:flex md:w-64 md:flex-col z-10 ml-3">
                <div class="overflow-y-auto h-32 flex min-h-[90%] flex-col my-auto shadow-xl bg-white rounded-2xl p-2">
                    <router-link to="/" class="hover:no-underline">
                        <div class="flex gap-3 justify-center items-center rounded-2xl h-16 flex-shrink-0 bg-white">
                            <img class="object-cover h-14 w-14" :src="company_logo" alt="Your Company" />
                            <h1 class="text-black text-lg font-bold space-x-3">{{ company_name }}</h1>
                        </div>
                        <hr class="text-gray-50 w-40 mx-auto" />
                    </router-link>
                    <div class="flex flex-1 flex-col overflow-y-auto">
                        <nav class="flex h-full flex-col justify-between space-y-3 px-2 py-4">
                            <div>
                                <template v-for="(item, i) in navigationItems" :key="item.name">
                                    <div v-if="item.name && item.subMenu && item.subMenu.length > 0"
                                        class="flex flex-col duration-500 relative py-[6px]">
                                        <div class="flex justify-between m-2 duration-1000 relative" @click="manageSubMenu(i)"
                                            :class="!isAllow(item) ? 'cursor-not-allowed' : 'cursor-pointer'">
                                            <div class="flex space-x-3">
                                                <component :is="item.icon"
                                                    :class="[item.current ? '' : '', ' flex-shrink-0 h-6 w-6', item.color ]"
                                                    aria-hidden="true" />
                                                <h3 class="flex justify-center items-center">{{ item.name }}</h3>
                                            </div>
                                            <span v-if="item.subMenu && item.subMenu.length > 0">
                                                <ChevronDownIcon class="h-4 material-icons fill-slate-500" aria-hidden="true" />
                                            </span>
                                        </div>
                                        <transition name="slide-fade">
                                            <div v-if="subMenuHandle[i]" class="h-full rounded-lg">
                                                <div v-for="(subMenu, subMenuIndex) in item.subMenu" :key="subMenu.name"
                                                    @click="(i, subMenuIndex)">
                                                    <router-link v-if="subMenu" :to="subMenu.href ? subMenu.href : '/'"
                                                        class="keyFrame mb-2 ml-3 button flex  p-2 hover:shadow-xl rounded-[20px] hover:scale-[1.01] transition-all hover: hover:no-underline space-x-3"
                                                        :class="{ 'router-link-active': isActiveRoute(subMenu.href) }">
                                                        <component :is="subMenu.icon"
                                                            :class="[subMenu.current ? '!text-white' : subMenu.color, 'flex-shrink-0 h-6 w-6 ']"
                                                            aria-hidden="true" />
                                                        <h3 class="flex justify-center items-center">{{ subMenu.name }}</h3>
                                                    </router-link>
                                                </div>
                                            </div>
                                        </transition>
                                    </div>
                                    <router-link v-else-if="item.name" :to="item.href ? item.href : '/'"
                                        @click="item.current = true"
                                        :class="[item.current ? '' : '', 'group flex items-center p-2 py-2  text-[14px] font-medium rounded-md no-underline space-x-4', {'router-link-active': isActiveRoute(item.href)}]"
                                        style="text-decoration: none;">
                                        <component :is="item.icon"
                                            :class="[item.current ? 'text-white!' : item.color, 'flex-shrink-0 h-6 w-6  ',]"
                                            aria-hidden="true" />
                                        <h3 :class="[item.current ? ' ' : '']">
                                            {{ item.name }}
                                        </h3>
                                    </router-link>
                                </template>
                            </div>
                            <div>
                                <router-link to="/" class="text-decoration-none">
                                    <div class="flex justify-center px-8 py-2 text-white bg-gray-800 rounded-lg">
                                        <a href="/login" @click="handleNavigation($event, 'logout')">
                                            <ArrowRightOnRectangleIcon class="inline-block w-6 h-6 mx-2" />
                                            Logout
                                        </a>
                                    </div>
                                </router-link>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
            <div class="flex flex-col md:pl-64"></div>
                 <main class="w-full text-gray-600 duration-500" v-if="rednerComponent">

                    <div :key="updateKey" class=" mx-auto md:max-w-full px-10 sm:px-6 md:px-8 z-10 relative pt-8">
                        <ToastList />
                        <slot name="main"></slot>
                    </div>
                </main>
        </div>
    </div>
</template>

<style scoped>
.router-link-active {
    background-color: #2c95a7; /* Change this to your desired active link background color */
    color: #ffffff; /* Change this to your desired active link text color */
    border-radius: 22px;
}
</style>
