<script setup>
import profileApi from '@/Utils/EmploypanalApi/ProfileApi';
import { onMounted, ref, defineProps, defineEmits } from 'vue';
import Modal from '@/Components/Modal.vue';
import InputLabel from '@/Components/InputLabel.vue';
import TextInput from '@/Components/TextInput.vue';
import SecondaryButton from '@/Components/SecondaryButton.vue';
import PrimaryButton from '@/Components/PrimaryButton.vue';
import FileUpload from '@/Components/FileUpload.vue';
import VueDatePicker from '@vuepic/vue-datepicker';
import moment from "moment";


import {
    validateEmployeeProfile
} from "@/Validation/validateProfile";
import store from '@/store';
import InputError from '@/Components/InputError.vue';
import {
    validatePassword
} from "@/Validation/validateChangePassword";
import { useStore } from 'vuex';





// const form = ref({ first_name: '', email: '', image: null, })
const form = ref({ old_password: '', password: '', password_confirmation: '', })
const formErrors = ref({});
const url = ref(null);
const files = ref('')
const profileform = ref(
    {
        first_name: '',
        email: '',
        image: '',
        age: '',
        // dob:'',
        dob: moment(),
        mobile: '',
        city: '',
        address: '',


    }
)
// const store = useStore();
// const form = ref({});
// const formErrors = ref({});
// const props = defineProps({
// changePasswordModal: Boolean
// })

// const emit = defineEmits(['submit', 'close-modal']);

const updatePassword = () => {
    console.log(321);
    const errors = validatePassword(form.value);
    formErrors.value = _.keyBy(errors.error?.details, 'context.key');

    if (!Object.keys(formErrors.value).length) {
        let payloads = {
            url: 'change-password',
            data: form.value
        }
        store.dispatch('update', payloads).then((response) => {
            store.commit('add', {
                message: response.data.message,
                type: 'success',
                duration: 3000,
            });
            emit('submit');
            form.value = {}
        }).catch(error => {
            if (error.response) {
                store.commit('add', {
                    message: error.response.data.message,
                    type: 'danger',
                    duration: 3000
                });
            }
        })

    } else {
        setTimeout(() => {
            formErrors.value = {};
        }, 5000)
    }
}


const props = defineProps({
    profileModal: Boolean
});

const addFile = (evt) => {
    const file = evt.target.files[0]
    url.value = URL.createObjectURL(file);
    files.value = file

}

const handleUpload = (file) => {
    profileform.value.image = file
};

const getProfile = () => {
    profileApi.get().then(response => {
        console.log(response, 'profile form321321');
        profileform.value = response.data.data;
        console.log(response.data.data.image, 'immaaihgf')
    }).catch(error => {
        console.log(error);
    })
}

// const emit = defineEmits(['closeModal','submit']);

const updateProfile = () => {
    const errors = validateEmployeeProfile(profileform.value);
    formErrors.value = _.keyBy(errors.error?.details, 'context.key');

    // if (!Object.keys(formErrors.value).length) {


    // if(!files.value){
    //     form.value.image = null;
    // }
    profileform.value.dob = moment(profileform.value.dob).format('YYYY-MM-DD');
    let payloads = {
        url: 'profile/update',
        data: profileform.value
    }
    
    console.log(profileform.value ,'/gfgggggggghghhgghghghghghghgh');

    store.dispatch('update', payloads).then((response) => {
        store.commit('add', {
            message: response.data.message,
            type: 'success',
            duration: 3000,
        });
        localStorage.setItem("user", JSON.stringify(response.data.data));
        // emit('closeModal');
        // emit('submit');
        profileform.value = { image: null }

    }).catch(error => {
        if (error.response) {
            store.commit('add', {
                message: error.response.data.message,
                type: 'danger',
                duration: 3000
            });
        }
    })

}
// else {
//     setTimeout(() => {
//         formErrors.value = {};
//     }, 5000)
// }
// }

onMounted(() => {
    getProfile(1);
    form.value.name = JSON.parse(localStorage.getItem('user')).first_name;
    form.value.email = JSON.parse(localStorage.getItem('user')).email.value;
});
</script>
<template>
    <!-- <Modal :isModalOpen="profileModal" @close="emit('close-modal')">
        <template #title>
            Profile
        </template>
<form class="p-3">
    <div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">

        <div class="col-span-3">
            <InputLabel for="name" value="Name"
                class="block mb-1 font-medium after:content-['*'] after:ml-0.5 after:text-red-500" />
            <TextInput id="name" type="text" class="mt-1 block w-full" v-model="form.first_name" required autofocus
                autocomplete="name" placeholder="Enter your name" />
            <InputError class="mt-2" :message="formErrors?.name?.message" />
        </div>
        <div class="col-span-3">
            <InputLabel for="email" value="email"
                class="block mb-1 font-medium after:content-['*'] after:ml-0.5 after:text-red-500" />
            <TextInput id="email" type="text" class="mt-1 block w-full" disabled v-model="form.email" required autofocus
                autocomplete="name" placeholder="Enter your email" />
            <InputError class="mt-2" :message="formErrors?.email?.message" />
        </div>

        <div class="col-span-6">
            <InputLabel for="file" value="Image"
                class="block mb-1 font-medium after:content-['*'] after:ml-0.5 after:text-red-500" />
            <div class="mt-2 cursor-pointer">
                <FileUpload @handleUpload="handleUpload" @change="addFile" />
            </div>
        </div>

        <div class="col-span-3">
            <div id="preview" class="w-20 h-20">
                <img v-if="url || form.image" :src="url || form.image" />
            </div>
        </div>


    </div>
</form>
<template #footer>
            <SecondaryButton @click="emit('closeModal')" type="submit">Cancel</SecondaryButton>
            <PrimaryButton @click="updateProfile" type="submit">Save</PrimaryButton>
        </template>
</Modal> -->

    <!-- Profile section -->

    <div>
        <div class="shadow-sm">
            <div
                class="lg:max-w-[98.5rem] bg-white  backdrop-blur-lg backdrop-saturate-200 !mt-5 mx-auto rounded-2xl p-2">
                <div class="px-4 sm:px-6 lg:mx-auto  lg:px-8">
                    <div class="py-2 md:flex md:items-center md:justify-between">
                        <div class="min-w-0 flex-1">
                            <div class="flex items-center">
                                <img class="hidden h-16 w-16 rounded-[10px] sm:block object-cover"
                                    :src="profileform.image" alt="" />
                                <div>
                                    <div class="flex items-center">
                                        <img class="h-16 w-16 rounded-full sm:hidden" :src="profileform.image" alt="" />
                                        <div class="flex flex-col ">
                                            <h1
                                                class="ml-3 text-1xl text-lg font-bold	 text-gray-900 sm:truncate  white">
                                                {{ profileform.first_name }}
                                            </h1>
                                            <p class="ml-3 text-1xl text-lg   text-gray-900 sm:truncate  white">
                                                Octal infotech
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <!-- -----------------------------------------------------Student Profile-------------------------------------------------------------- -->

    <div class=" mx-[35px] pt-3  flex justify-center">
        <!-- <div class="w-[100%] flex justify-center"> -->
        <div class="col-md-8 bg-white rounded-[20px] shadow-2xl w-[1000px]">
            <div class="card p-4 rounded-[20px] shadow">
                <div class=" pb-0 ">
                    <div class="flex items-center justify-between p-2">
                        <p class="mb-0  pb-2  font-bold">
                            Profile
                        </p>


                        <!-- <button class=" bg-teal-400 text-white rounded-md py-1 px-4">
                                Setting
                            </button> -->
                    </div>
                    <hr>
                </div>
                <div class="card-body p-2">
                    <p class="uppercase   text-md mb-2 mt-2">
                        User information
                    </p>
                    <div class="grid lg:grid-cols-2 gap-4">
                        <div>
                            <label for="name"
                                class="text-sm text-gray-700 block mb-1 font-medium after:content-['*'] after:ml-0.5 after:text-red-500">
                                User Name</label>
                            <input v-model="profileform.first_name" type="text" name="name" id="name" class=" border border-gray-200 rounded-md p-2 block
                                            focus:ring-blue-500 focus:border-blue-500 text-gray-700 w-full"
                                placeholder="Enter your name" />

                            <div class="input-errors text-red-700">
                                <div class="error-msg"></div>
                            </div>
                        </div>
                        <div>
                            <label for="email"
                                class="text-sm text-gray-700 block mb-1 font-medium after:content-['*'] after:ml-0.5 after:text-red-500">Email
                                adress</label>
                            <input disabled v-model="profileform.email" type="email" name="email" id="email" class=" border border-gray-200 rounded-md p-2 block
                                            focus:ring-blue-500 focus:border-blue-500 text-gray-700 w-full"
                                placeholder="Enter your Email adress" />

                            <div class="input-errors text-red-700">
                                <div class="error-msg"></div>
                            </div>
                        </div>
                        <div>
                            <label for="name"
                                class="text-sm text-gray-700 block mb-1 font-medium after:content-['*'] after:ml-0.5 after:text-red-500">
                                Age</label>
                            <input v-model="profileform.age" type="text" name="name" id="name" class=" border border-gray-200 rounded-md p-2 block
                                            focus:ring-blue-500 focus:border-blue-500 text-gray-700 w-full"
                                placeholder="Enter your age" />

                            <div class="input-errors text-red-700">
                                <div class="error-msg"></div>
                            </div>
                        </div>
                        <div>
                            <label for="name"
                                class="text-sm text-gray-700 block mb-1 font-medium after:content-['*'] after:ml-0.5 after:text-red-500">
                                DOB</label>
                            <VueDatePicker type="date" inputFormat="yyyy-MM-dd" name="joindate" id="joindate"
                                class="mt-1 block w-full" :enableTimePicker="false" v-model="profileform.dob" />
                            <!-- <input v-model="profileform.dob" type="date" name="name" id="name" class=" border border-gray-200 rounded-md p-2 block
                                            focus:ring-blue-500 focus:border-blue-500 text-gray-700 w-full"
                                    placeholder="Enter your name" /> -->

                            <div class="input-errors text-red-700">
                                <div class="error-msg"></div>
                            </div>
                        </div>
                        <div>
                            <label for="name"
                                class="text-sm text-gray-700 block mb-1 font-medium after:content-['*'] after:ml-0.5 after:text-red-500">
                                Mobile</label>
                            <input v-model="profileform.mobile" type="text" name="name" id="name" class=" border border-gray-200 rounded-md p-2 block
                                            focus:ring-blue-500 focus:border-blue-500 text-gray-700 w-full"
                                placeholder="Enter your mobile" />

                            <div class="input-errors text-red-700">
                                <div class="error-msg"></div>
                            </div>
                        </div>

                        <div>
                            <label for="email"
                                class="text-sm text-gray-700 block mb-1 font-medium after:content-['*'] after:ml-0.5 after:text-red-500">
                                City</label>
                            <input v-model="profileform.city" type="email" name="email" id="email" class=" border border-gray-200 rounded-md p-2 block
                                focus:ring-blue-500 focus:border-blue-500 text-gray-700 w-full"
                                placeholder="Enter your city" />

                            <div class="input-errors text-red-700">
                                <div class="error-msg"></div>
                            </div>
                        </div>
                        <div>
                            <label for="name"
                                class="text-sm text-gray-700 block mb-1 font-medium after:content-['*'] after:ml-0.5 after:text-red-500">
                                Address </label>
                            <textarea v-model="profileform.address" type="text" name="name" id="name" class=" border border-gray-200 rounded-md p-2 block
                                                focus:ring-blue-500 focus:border-blue-500 text-gray-700 w-full"
                                placeholder="Enter your Address" />

                            <div class="input-errors text-red-700">
                                <div class="error-msg"></div>
                            </div>
                        </div>


                        <!-- <div>
                                <label for="address"
                                    class="text-sm text-gray-700 block mb-1 font-medium after:content-['*'] after:ml-0.5 after:text-red-500">first
                                    Name</label>
                                <input type="text" name="address" id="address" class="border border-gray-200 rounded-md p-2 block
                                            focus:ring-blue-500 focus:border-blue-500 text-gray-700 w-full"
                                    placeholder="Enter your first Name" />

                                <div class="input-errors text-red-700">
                                    <div class="error-msg"></div>
                                </div>
                            </div> -->
                        <div>
                            <label for="phone" class="text-sm text-gray-700 block  font-medium">file</label>
                            <FileUpload @handleUpload="handleUpload" @change="addFile" />
                            <!-- <input @handleUpload="handleUpload" @change="addFile" type="file" name="phone" id="phone" class=" border border-gray-200 rounded-md p-2 block
                                            focus:ring-blue-500 focus:border-blue-500 text-gray-700 w-full"
                                    placeholder="Enter your Last name" /> -->
                        </div>
                        <div id="preview" class="w-20 h-20 rounded-full">
                            <img v-if="url || profileform.image" :src="url || profileform.image" />
                        </div>
                    </div>


                    <button type="submit" @click="updateProfile()"
                        class=" bg-gray-700 text-white rounded-md py-1 px-4">
                        Save
                    </button>

                </div>
                <!-- ==============================update password===== -->
                <div class="card-body p-2">
                    <p class=" uppercase  text-md mb-2  mt-4">
                        update password
                    </p>
                    <form>
                        <div class="mt-3 grid grid-cols-1 gap-x-6 gap-y-5">
                            <div class="col-span-3">

                                <InputLabel for="Old Password" value="Old Password"
                                    class="block mb-1 font-medium after:content-['*'] after:ml-0.5 after:text-red-500" />
                                <TextInput id="old_password" type="text" class="mt-1 block w-full"
                                    v-model="form.old_password" required autofocus autocomplete="name"
                                    placeholder="Enter your Old Password" />
                                <InputError class="mt-2" :message="formErrors?.old_password?.message" />
                            </div>

                            <div class="col-span-3">
                                <InputLabel for="Password" value="Password"
                                    class="block mb-1 font-medium after:content-['*'] after:ml-0.5 after:text-red-500" />
                                <TextInput id="password" type="text" class="mt-1 block w-full" v-model="form.password"
                                    required autofocus autocomplete="name" placeholder="Enter your Password" />
                                <InputError class="mt-2" :message="formErrors?.password?.message" />
                            </div>

                            <div class="col-span-3">
                                <InputLabel for="password confirmation" value="password confirmation"
                                    class="block mb-1 font-medium after:content-['*'] after:ml-0.5 after:text-red-500" />
                                <TextInput id="password_confirmation" type="text" class="mt-1 block w-full"
                                    v-model="form.password_confirmation" required autofocus autocomplete="name"
                                    placeholder="Enter your Password Confirmation" />
                                <InputError class="mt-2" :message="formErrors?.password_confirmation?.message" />
                            </div>

                        </div>
                    </form>


                </div>
                <button @click="updatePassword" class=" bg-gray-700 text-white rounded-md py-1 px-4 mt-4">
                    Update Password
                </button>
            </div>
        </div>
        <!-- </div> -->
    </div>

    <!-- <div class="col-md-4 bg-white rounded-[20px] shadow " >
                <div >
                    <div class="row justify-center">
                        <img src="https://demos.creative-tim.com/vue-argon-dashboard/img/bg-profile.9af971bf.jpg" class="rounded-t-[20px]"> 
                        <div class="col-4 col-lg-4 order-lg-2">
                            <div class="mt-n4 mt-lg-n6 mb-4 mb-lg-0">
                                <img src="https://demos.creative-tim.com/vue-argon-dashboard/img/team-2.de26a134.jpg"
                                        class="rounded-circle img-fluid  border-2 border-white">
                                </div>
                        </div>
                    </div>
                    <div class="card-header text-center border-0 bg-white pt-lg-2 pb-4 pb-lg-3">
                        <div class="flex justify-between bg-white">
                            <a class=" bg-sky-500 text-white rounded-md py-1 px-4">Connect</a>
                            <a class="">
                            </a>
                            <a class=" bg-cyan-900 text-white rounded-md py-1 px-4">Message</a>
                            <a class=" float-right mb-0 d-block d-lg-none">
                               
                            </a>
                        </div>
                    </div>
                    <div class="rounded-xl">
                        <div class="row">
                            <div class="col">
                                <div class="flex justify-center">
                                    <div class=" text-center"><span
                                            class="text-lg ">22</span><span
                                            class="text-sm opacity-8">Friends</span></div>
                                    <div class="text-center mx-4"><span
                                            class="text-lg ">10</span><span
                                            class="text-sm opacity-8">Photos</span></div>
                                    <div class=" text-center"><span
                                            class="text-lg ">89</span><span
                                            class="text-sm opacity-8">Comments</span></div>
                                </div>
                            </div>
                        </div>
                        <div class="text-center mt-4">
                            <h5> Mark Davis , 35</h5>
                            <div>Bucharest, Romania
                            </div>
                            <div class=" mt-4">Solution Manager -
                                Creative Tim Officer </div>
                            <div>University of Computer Science </div>
                        </div>
                    </div>
                </div>
            </div> -->
    <!-- </d-iv> -->





</template>