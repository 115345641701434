<script setup>
import { reactive, watch } from 'vue';
import { useDropzone } from 'vue3-dropzone';

const emit = defineEmits(['handleUpload'])

const state = reactive({
    files: [],
});

const { getRootProps,  getInputProps, isDragActive, ...rest } = useDropzone({
    onDrop,
    maxFiles:1
});

watch(state, () => {
    emit('handleUpload', state.files[0])
});

watch(isDragActive, () => {
    console.log('isDragActive', isDragActive.value, rest);
});

function onDrop(acceptFiles, rejectReasons) {
    // console.log(acceptFiles);
    // console.log(rejectReasons);
    state.files = acceptFiles;
}

function handleClickDeleteFile(index) {
    state.files.splice(index, 1);
}
</script>

<template>
    <div>
        <div v-if="state.files.length > 0" class="w-full p-2 border-2 border-dashed rounded-md shadow-md border-gray-900">
            <div class="rounded-sm flex items-center justify-between bg-gray-900  text-white" v-for="(file, index) in state.files" :key="index">
                <span class="pl-3">{{ file.name }}</span>
                <span class="px-3 text-sm py-2 shadow-md bg-red-500 text-white rounded-sm cursor-pointer" @click="handleClickDeleteFile(index)">Delete</span>
            </div>
        </div>
        <div v-else class="w-full p-1.5 border-2 rounded-md shadow-md border-gray-900 border-dashed" v-bind="getRootProps()">
            <div class="flex items-center justify-center p-2 transition-all ease-in-out duration-300 bg-gray-900 text-white " :class="{
                isDragActive,
            }">
                <input v-bind="getInputProps()" />
                <p class="text-sm" v-if="isDragActive">Drop the files here ...</p>
                <p class="text-sm" v-else>Drag and drop files here, or Click to select files</p>
            </div>
        </div>
    </div>
</template>
