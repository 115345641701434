import { createStore } from "vuex";
import _ from "lodash";
import axios from "axios";
import authHeader from "./Utils/setAuthHeader";
let API_URL = process.env.VUE_APP_ROOT_API;
let EMP_URL = process.env.VUE_APP_ROOT_EMPLOYEE_API;

export default createStore({
  state: {
    user: "",
    employee: "",
    permissions: [],
    items: [],
    settings: {
      type: "success",
      duration: 3000,
    },
  },
  mutations: {
    loginUser(state, user) {
      state.user = user;
      localStorage.setItem("token", user.token);
      localStorage.setItem("user", JSON.stringify(user));
    },
    logout(state) {
      state.user = "";
      state.permissions = [];
      localStorage.clear();
    },
    initialiseStore(state) {
      if (localStorage.getItem("user")) {
        let user = JSON.parse(localStorage.getItem("user"));
        let permissions = user.role?.permission;
        state.user = user;

        state.permissions = permissions ? _.map(permissions, "name") : [];
      }
    },
    setPermissions(state, permissions) {
      if (permissions) {
        state.permissions = _.map(permissions, "name");
      }
    },
    add(state, toast) {
      (state.settings.type = toast.type),
        (state.settings.duration = toast.duration),
        state.items.unshift({
          key: Symbol(),
          ...toast,
        });
    },
    remove(state, index) {
      state.items.splice(index, 1);
    },
  },
  actions: {
    getListings: function (state, url) {
      return axios.get(API_URL + url, { headers: authHeader() });
    },
    getListingsEmp: function (state, url) {
      return axios.get(EMP_URL + url, { headers: authHeader() });
    },
    getTodoListingAll: function (state, url) {
      return axios.get(EMP_URL + url, { headers: authHeader() });
    },
    getTodoListingAllApi: function (state, url) {
      return axios.get(API_URL + url, { headers: authHeader() });
    },
    storeRecordEmp: function (state, payload) {
      return axios.post(EMP_URL + payload.url, payload.data, {
        headers: authHeader(),
      });
    },
    // deleteRecordEmp: function (state,url) {
    //     return axios.delete(EMP_URL + url, { headers: authHeader() });
    // },
    updateRecordEmp: function (state, payload) {
      return axios.post(EMP_URL + payload.url, payload.data, {
        headers: authHeader(),
      });
    },
    updateRecordApi: function (state, payload) {
      return axios.post(API_URL + payload.url, payload.data, {
        headers: authHeader(),
      });
    },
    // ClearAllRecordEmp: function (state, url) {
    //     return axios.delete(EMP_URL + url, { headers: authHeader() });
    // },
    // ClearCompetedRecordEmp: function (state, url) {
    //     return axios.delete(EMP_URL + url, { headers: authHeader() });
    // },
    storeRecord: function (state, payload) {
      return axios.post(API_URL + payload.url, payload.data, {
        headers: authHeader(),
      });
    },
    updateRecord: function (state, payload) {
      return axios.post(API_URL + payload.url, payload.data, {
        headers: authHeader(),
      });
    },
    update: function (state, payload) {
      return axios.post(EMP_URL + payload.url, payload.data, {
        headers: authHeader(),
      });
    },
    showRecord: function (state, url) {
      return axios.post(API_URL + url, { headers: authHeader() });
    },
    deleteRecord: function (state, url) {
      return axios.delete(API_URL + url, { headers: authHeader() });
    },
  },
});
